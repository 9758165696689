import Helmet from 'react-helmet';
import React, { Component } from 'react';

import withAccount from '../../../containers/plugins/withAccount';
import { getCmsValue } from '../../../utils/utils';

class Login extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onCredentialsChange = this.onCredentialsChange.bind(this);
    this.onCredentialsSubmit = this.onCredentialsSubmit.bind(this);
    this.onPasswordResetClick = this.onPasswordResetClick.bind(this);
    this.onPasswordResetSubmit = this.onPasswordResetSubmit.bind(this);

    this.initialState = {
      username: '',
      password: '',
      passwordReset: false,
      passwordResetLoading: false,
      passwordResetMessage: null,
      loading: false,
      error: null,
    };

    this.state = {
      ...this.initialState,
    };
  }

  onCredentialsChange(e) {
    this.setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  onPasswordResetClick() {
    const { passwordReset } = this.state;

    this.setState((prevState) => ({
      ...prevState,
      passwordReset: !passwordReset,
      error: null,
      loading: false,
    }));
  }

  async onSubmit(e) {
    e.preventDefault();
    const { passwordReset } = this.state;

    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    let response;

    if (passwordReset) {
      response = await this.onPasswordResetSubmit();
    } else {
      response = await this.onCredentialsSubmit();
    }

    this.setState((prevState) => ({
      ...prevState,
      loading: false,
    }));

    if (response.error) {
      this.setState((prevState) => ({
        ...prevState,
        error: response.error.message,
      }));
    } else if (response.customerUserErrors.length !== 0) {
      this.setState((prevState) => ({
        ...prevState,
        error: response.customerUserErrors[0].message,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        error: passwordReset
          ? 'Follow the instructions sent to your email'
          : null,
      }));
    }
  }

  async onCredentialsSubmit() {
    const { login } = this.props;

    return await login({
      email: this.state.username,
      password: this.state.password,
    });
  }

  async onPasswordResetSubmit() {
    const { recoverPassword } = this.props;
    const { username } = this.state;

    return await recoverPassword(username);
  }

  render() {
    const { site } = this.props;
    const { username, password, passwordReset } = this.state;

    return (
      <div className="account-section account-section__login">
        <div className="account-section__title">
          <h3>
            {passwordReset
              ? getCmsValue(
                  site.strings.accountPage__profile__passwordResetText,
                  site
                )
              : getCmsValue(site.strings.accountPage__login__title, site)}
          </h3>
        </div>

        <form className="account-section__form" onSubmit={this.onSubmit}>
          <div className="form__input-wrapper">
            <label htmlFor="username">
              {getCmsValue(site.strings.accountPage__login__username, site)}
              <input
                name="username"
                type="email"
                value={username}
                onChange={this.onCredentialsChange}
                required
              />
            </label>
            {!passwordReset && (
              <label htmlFor="password">
                {getCmsValue(site.strings.accountPage__login__password, site)}
                <input
                  name="password"
                  type="password"
                  value={password}
                  onChange={this.onCredentialsChange}
                  required
                />
              </label>
            )}
            <span className="error">{this.state.error}</span>
          </div>

          <div className="form__submit-wrapper">
            <span
              className="resetPassword link"
              onClick={this.onPasswordResetClick}
            >
              {passwordReset
                ? getCmsValue(site.strings.accountPage__login__title, site)
                : getCmsValue(
                    site.strings.accountPage__profile__passwordResetText,
                    site
                  )}
            </span>

            <button type="submit" disabled={this.state.loading}>
              {passwordReset
                ? getCmsValue(
                    site.strings.accountPage__profile__passwordResetText,
                    site
                  )
                : getCmsValue(site.strings.accountPage__login__login, site)}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default withAccount(Login);
