import React, { Component } from 'react';

class Tier extends Component {
  render() {
    const { customer, tier } = this.props;

    const tierActive = tier.name === customer.vip_tier_name;

    const classNames = [
      'loyalty__tier',
      `loyalty__tier--${tier.name}`,
      tierActive ? `loyalty__tier--active` : '',
    ];

    return (
      <li className={classNames.join(' ')}>
        <span className="tier-name">{tier.name}</span>
        <span className="tier-indicator" />
        <div className="tier-body">
          <span>{tier.points_multiplier}x points multiplier</span>
          <span>{tier.points_reward} points entry reward</span>
        </div>
      </li>
    );
  }
}

export default Tier;
