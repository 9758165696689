import PhoneInput from 'react-phone-number-input';
import React, { Component } from 'react';

import withAccount from '../../../containers/plugins/withAccount';
import { getCmsValue } from '../../../utils/utils';
import { setAccount } from '../../../state/accountService';

class Customer extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      passwordResetLoading: false,
      passwordResetMessage: null,
      loading: false,
      error: null,
    };

    this.state = {
      ...this.initialState,
    };

    this.onPhoneChange = this.onPhoneChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onPasswordResetSubmit = this.onPasswordResetSubmit.bind(this);
  }

  onPhoneChange(number) {
    if (!number) return;

    this.onChange({
      target: {
        name: 'phone',
        value: number,
      },
    });
  }

  onChange(e) {
    const { account, dispatch, site } = this.props;

    const newAccount = {
      ...account,
      [e.target.name]: e.target.value,
    };

    dispatch(setAccount(newAccount, site.siteUID));
  }

  async onSubmit(e) {
    e.preventDefault();

    const { updateAccount } = this.props;

    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    const response = await updateAccount();

    this.setState((prevState) => ({
      ...prevState,
      loading: false,
    }));

    if (response.error) {
      this.setState((prevState) => ({
        ...prevState,
        error: response.error.message,
      }));
    } else if (response.customerUserErrors.length !== 0) {
      this.setState((prevState) => ({
        ...prevState,
        error: response.customerUserErrors[0].message,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        error: null,
      }));
    }
  }

  async onPasswordResetSubmit() {
    const { account, recoverPassword, site } = this.props;

    this.setState((prevState) => ({
      ...prevState,
      passwordResetLoading: true,
    }));

    const response = await recoverPassword(account.email);

    this.setState((prevState) => ({
      ...prevState,
      passwordResetLoading: false,
    }));

    if (response.customerUserErrors.length === 0) {
      this.setState((prevState) => ({
        ...prevState,
        passwordResetMessage: getCmsValue(
          site.strings.accountPage__profile__passwordResetMessage,
          site
        ),
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        passwordResetMessage: response.customerUserErrors[0].message,
      }));
    }
  }

  render() {
    const { account, site } = this.props;

    return (
      <div className="account-section account-section__information">
        <div className="account-section__title">
          <h3>
            {getCmsValue(
              site.strings.accountPage__profile__informationTitle,
              site
            )}
          </h3>
        </div>
        <form className="account-section__form" onSubmit={this.onSubmit}>
          <div className="form__input-wrapper">
            <label htmlFor="firstName">
              {getCmsValue(
                site.strings.accountPage__profile__editInformationFirstName,
                site
              )}
              <input
                name="firstName"
                type="text"
                value={account.firstName || ''}
                onChange={this.onChange}
                required
              />
            </label>
            <label htmlFor="lastName">
              {getCmsValue(
                site.strings.accountPage__profile__editInformationLastName,
                site
              )}
              <input
                name="lastName"
                type="text"
                value={account.lastName || ''}
                onChange={this.onChange}
                required
              />
            </label>
            <label htmlFor="phone">
              {getCmsValue(
                site.strings.accountPage__profile__editInformationPhone,
                site
              )}
              <PhoneInput
                onChange={this.onPhoneChange}
                value={account.phone || ''}
                defaultCountry="US"
              />
            </label>
            <label htmlFor="email">
              {getCmsValue(
                site.strings.accountPage__profile__editInformationEmail,
                site
              )}
              <input
                name="email"
                type="email"
                value={account.email || ''}
                onChange={this.onChange}
                required
              />
            </label>
            <span className="error">{this.state.error}</span>
          </div>
          <div className="form__submit-wrapper">
            {this.state.passwordResetMessage ? (
              <span className="resetPassword">
                {this.state.passwordResetMessage}
              </span>
            ) : (
              <span
                className="resetPassword link"
                onClick={this.onPasswordResetSubmit}
              >
                {getCmsValue(
                  site.strings.accountPage__profile__passwordResetText,
                  site
                )}
              </span>
            )}
            <button type="submit" disabled={this.state.loading}>
              {getCmsValue(
                site.strings.accountPage__profile__informationSubmit,
                site
              )}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default withAccount(Customer);
