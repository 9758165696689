import Helmet from 'react-helmet';
import React, { Component } from 'react';

import Link from '../Link';
import withAccount from '../../containers/plugins/withAccount';
import { getCmsValue } from '../../utils/utils';

class AccountFrame extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { accessToken, account, children, logout, site, title } = this.props;

    const to = (path) => ({
      brandUID: site.brandUID,
      path: path,
      siteUID: site.siteUID,
    });

    return (
      <article className="page article account">
        <Helmet title={title} />

        <header className="article__header ">
          <div className="article__featured">
            <div className="article__featured-text">
              <h1 className="article__title">{title}</h1>
              {accessToken && account ? (
                <ul className="account__nav-bar-menu-list">
                  <li>
                    <Link site={site} to={to('account/loyalty')}>
                      Earn Points
                    </Link>
                  </li>
                  <li>
                    <Link site={site} to={to('account')}>
                      {getCmsValue(
                        site.strings.accountPage__profile__title,
                        site
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link site={site} to={to('account/orders')}>
                      {getCmsValue(
                        site.strings.accountPage__orders__title,
                        site
                      )}
                    </Link>
                  </li>
                  <li>
                    <a href="#!" onClick={logout}>
                      {getCmsValue(
                        site.strings.accountPage__logout__title,
                        site
                      )}
                    </a>
                  </li>
                </ul>
              ) : (
                <ul className="account__nav-bar-menu-list">
                  <li>
                    <Link site={site} to={to('account/loyalty')}>
                      Earn Points
                    </Link>
                  </li>
                  <li>
                    <Link site={site} to={to('account')}>
                      {getCmsValue(
                        site.strings.accountPage__login__title,
                        site
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link site={site} to={to('account/register')}>
                      {getCmsValue(
                        site.strings.accountPage__register__title,
                        site
                      )}
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </header>

        <div className="account__main">{children}</div>
      </article>
    );
  }
}

export default withAccount(AccountFrame);
