import React, { Component } from 'react';
import RedemptionOption from './RedemptionOption';

class RedemptionOptions extends Component {
  render() {
    const { customer, loyalty, onCopy, onRedemption, redemptionOptions } =
      this.props;

    return (
      <ul className="loyalty-section loyalty__redemption-options">
        {redemptionOptions?.map((redemptionOption) => (
          <RedemptionOption
            key={redemptionOption.id}
            customer={customer}
            loyalty={loyalty}
            onCopy={onCopy}
            onRedemption={onRedemption}
            redemptionOption={redemptionOption}
          />
        ))}
      </ul>
    );
  }
}

export default RedemptionOptions;
