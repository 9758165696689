import React, { Component } from 'react';

import Link from '../../Link';
import RedemptionOptions from './loyalty/RedemptionOptions';
import SiteLoader from '../../_common/SiteLoader';
import Tiers from './loyalty/Tiers';
import withAccount from '../../../containers/plugins/withAccount';
import { Copy } from '../../_common/Icons';

class Loyalty extends Component {
  constructor(props) {
    super(props);

    this.getLoyalty = this.getLoyalty.bind(this);
    this.onReferralLinkCopy = this.onReferralLinkCopy.bind(this);

    this.initialState = {
      customer: null,
      redemptionOptions: [],
      referralLink: null,
      vipTiers: [],
    };

    this.state = {
      ...this.initialState,
    };
  }

  componentDidMount() {
    this.getLoyalty();
  }

  componentDidUpdate(prevProps) {
    const { account } = this.props;

    if (prevProps.account?.email !== account?.email) {
      this.getLoyalty();
    }
  }

  async getLoyalty() {
    const { account, loyalty } = this.props;

    const customer = await loyalty.customers(account.email);
    const redemptionOptions = await loyalty.redemptionOptions(account.email);
    const referrer = await loyalty.referralReferrer(account.email);
    const referralLink = referrer.referral_link;
    const vipTiers = await loyalty.vipTiers();

    this.setState((prevState) => ({
      ...prevState,
      customer,
      redemptionOptions,
      referralLink,
      vipTiers,
    }));
  }

  async onRedemption() {
    const { account, loyalty } = this.props;
    const customer = await loyalty.customers(account.email);

    this.setState((prevState) => ({
      ...prevState,
      customer,
    }));
  }

  async onCopy() {
    const { account, loyalty } = this.props;
    const redemptionOptions = await loyalty.redemptionOptions(account.email);

    this.setState((prevState) => ({
      ...prevState,
      redemptionOptions,
    }));
  }

  onReferralLinkCopy() {
    const { referralLink } = this.state;
    navigator.clipboard.writeText(referralLink);
  }

  render() {
    const { loyalty, site } = this.props;
    const { customer, redemptionOptions, vipTiers } = this.state;

    const to = (path) => ({
      brandUID: site.brandUID,
      path: path,
      siteUID: site.siteUID,
    });

    return (
      <div className="account-section account-section__loyalty">
        <div className="account-section__title">
          <h3>LOYALTY</h3>
        </div>
        <div className="account-section__form">
          <div className="form__input-wrapper">
            {customer !== null ? (
              <>
                <div className="loyalty-section loyalty__balance">
                  <h4>Balance</h4>
                  <h1 className="points">{customer.points_balance} points</h1>
                </div>
                {vipTiers.length > 0 && (
                  <Tiers
                    customer={customer}
                    loyalty={loyalty}
                    vipTiers={vipTiers}
                  />
                )}
                {redemptionOptions.length > 0 && (
                  <RedemptionOptions
                    customer={customer}
                    loyalty={loyalty}
                    onCopy={this.onCopy.bind(this)}
                    onRedemption={this.onRedemption.bind(this)}
                    redemptionOptions={redemptionOptions}
                  />
                )}
                <div className="loyalty-section loyalty__footer">
                  <span className="referral" onClick={this.onReferralLinkCopy}>
                    <span>Copy referral link </span>
                    {Copy}
                  </span>
                  <span className="earn-more">
                    <Link site={site} to={to('account/loyalty')}>
                      <h4>Earn more points?</h4>
                    </Link>
                  </span>
                </div>
              </>
            ) : (
              <SiteLoader />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withAccount(Loyalty);
