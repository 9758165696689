import React, { Component } from 'react';

import Tier from './Tier';

class Tiers extends Component {
  render() {
    const { customer, vipTiers } = this.props;

    return (
      <ul className="loyalty-section loyalty__tiers">
        {vipTiers?.map((tier) => (
          <Tier key={tier.name} customer={customer} tier={tier} />
        ))}
      </ul>
    );
  }
}

export default Tiers;
