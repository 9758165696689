import axios from 'axios';
import React, { Component } from 'react';

function withKlaviyo(WrappedComponent) {
  class WithKlaviyo extends Component {
    constructor(props) {
      super(props);

      this.getPerson = this.getPerson.bind(this);
      this.identify = this.identify.bind(this);

      this.getKlaviyo = this.getKlaviyo.bind(this);
      this.mapToItem = this.mapToItem.bind(this);
      this.addedToCart = this.addedToCart.bind(this);
      this.searchedFor = this.searchedFor.bind(this);
      this.viewedProduct = this.viewedProduct.bind(this);

      this.state = {};
    }

    componentWillUnmount() {
      this.setState = (state, callback) => {
        return;
      };
    }

    async getPerson(email) {
      const response = await axios({
        method: 'GET',
        url: `/api/klaviyo/v2/people/search?email=${email}`,
      }).then((res) => res.data);

      return response;
    }

    async identify(email, properties = {}) {
      const klaviyo = this.getKlaviyo();

      const result = await klaviyo.push([
        'identify',
        {
          $email: email,
          ...properties,
        },
      ]);
    }

    getKlaviyo() {
      if (typeof window !== 'undefined') {
        return window.klaviyo || [];
      } else {
        return [];
      }
    }

    mapToItem(product, variant, view) {
      const defaultVariant = product.variants.sort((a, b) =>
        a.price < b.price ? -1 : 1
      )[0];

      return {
        AddedFrom: view,
        Name: product.name.value?.en,
        ProductID: product.sourceId,
        Categories: [product.category.value?.en, product.subCategory.value?.en],
        ImageURL: product.images[0]?.url || '',
        URL: `${window.location.origin}${window.location.pathname}`,
        Brand: product.brand.value,
        Price: variant ? variant.price : defaultVariant?.price || '',
        CompareAtPrice: variant
          ? variant.oldPrice
          : defaultVariant?.oldPrice || '',
      };
    }

    async addedToCart(product, variant, view) {
      const klaviyo = this.getKlaviyo();
      const data = this.mapToItem(product, variant, view);

      await klaviyo.push(['track', 'Added to Cart', data]);
    }

    async searchedFor(location, query) {
      const klaviyo = this.getKlaviyo();
      const data = {
        Location: location,
        Query: query,
      };

      await klaviyo.push(['track', 'Searched for', data]);
    }

    async viewedProduct(product, variant) {
      const klaviyo = this.getKlaviyo();
      const data = this.mapToItem(product, variant);

      await klaviyo.push(['track', 'Viewed Product', data]);
      await klaviyo.push([
        'trackViewedItem',
        {
          Title: data.Name,
          ItemId: data.ProductID,
          Categories: data.Categories,
          ImageUrl: data.ImageURL,
          Url: data.URL,
          Metadata: {
            Brand: data.Brand,
            Price: data.Price,
            CompareAtPrice: data.CompareAtPrice,
          },
        },
      ]);
    }

    render() {
      const { ...props } = this.props;

      const klaviyo = {
        getPerson: this.getPerson,
        identify: this.identify,
        addedToCart: this.addedToCart,
        searchedFor: this.searchedFor,
        viewedProduct: this.viewedProduct,
      };

      return <WrappedComponent klaviyo={klaviyo} {...props} />;
    }
  }

  return WithKlaviyo;
}

export default withKlaviyo;
