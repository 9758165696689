import React, { Component } from 'react';
import Select from 'react-select';

import rawSpecies from './ProductSegmentationSpecies.json';
import rawWaterTypes from './ProductSegmentationWaterType.json';
import withAccount from '../../../containers/plugins/withAccount';
import withKlaviyo from '../../../containers/plugins/withKlaviyo';
import { getCmsValue, getPimValue } from '../../../utils/utils';

class Preferences extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      person: {
        PreferredSpecies: [],
        PreferredWaterType: [],
      },
      loading: false,
      error: null,
    };

    this.state = {
      ...this.initialState,
    };

    this.getPerson = this.getPerson.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.getPerson();
  }

  async getPerson() {
    const { account, klaviyo } = this.props;

    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    const person = await klaviyo.getPerson(account.email).then((res) => res);

    this.setState((prevState) => ({
      ...prevState,
      loading: false,
      person: {
        PreferredSpecies: person.PreferredSpecies || [],
        PreferredWaterType: person.PreferredWaterType || [],
      },
    }));
  }

  onChange(values, element) {
    const set = new Set();
    values.forEach((value) => set.add(value?.value));

    this.setState((prevState) => ({
      ...prevState,
      person: {
        ...prevState.person,
        [element.name]: Array.from(set),
      },
    }));
  }

  async onSubmit(e) {
    e.preventDefault();

    const { account, klaviyo } = this.props;

    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    const response = await klaviyo.identify(account.email, this.state.person);

    this.setState((prevState) => ({
      ...prevState,
      loading: false,
    }));
  }

  render() {
    const { account, site } = this.props;
    const { person } = this.state;

    return (
      <div className="account-section account-section__information">
        <div className="account-section__title">
          <h3>
            {getCmsValue(
              site.strings.accountPage__profile__preferencesTitle,
              site
            )}
          </h3>
        </div>
        <form className="account-section__form" onSubmit={this.onSubmit}>
          <div className="form__input-wrapper">
            <label htmlFor="PreferredSpecies">
              {getCmsValue(
                site.strings
                  .accountPage__profile__editPreferencesPreferredSpecies,
                site
              )}
              <Select
                name="PreferredSpecies"
                onChange={this.onChange}
                isMulti={true}
                placeholder={'Species'}
                options={rawSpecies.map((rawItem) => ({
                  label: getPimValue(rawItem),
                  value: rawItem.key,
                }))}
                value={person.PreferredSpecies.map((item) => ({
                  label: getPimValue(
                    rawSpecies.find((rawItem) => item === rawItem.key)
                  ),
                  value: item,
                }))}
              />
            </label>

            <label htmlFor="PreferredWaterType">
              {getCmsValue(
                site.strings
                  .accountPage__profile__editPreferencesPreferredWaterType,
                site
              )}
              <Select
                name="PreferredWaterType"
                onChange={this.onChange}
                isMulti={true}
                placeholder={'Water Type'}
                options={rawWaterTypes.map((rawItem) => ({
                  label: getPimValue(rawItem),
                  value: rawItem.key,
                }))}
                value={person.PreferredWaterType.map((item) => ({
                  label: getPimValue(
                    rawWaterTypes.find((rawItem) => item === rawItem.key)
                  ),
                  value: item,
                }))}
              />
            </label>
          </div>

          <div className="form__submit-wrapper">
            <button type="submit" disabled={this.state.loading}>
              {getCmsValue(
                site.strings.accountPage__profile__preferencesSubmit,
                site
              )}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default withKlaviyo(Preferences);
