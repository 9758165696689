import React, { Component } from 'react';

import ProfileComponent from '../../components/account/Profile';

class Account extends Component {
  render() {
    const { site } = this.props;

    return <ProfileComponent site={site} />;
  }
}

export default Account;
