import PhoneInput from 'react-phone-number-input';
import React, { Component } from 'react';
import Select from 'react-select';

import countries from '../countries.json';
import withAccount from '../../../containers/plugins/withAccount';
import { getCmsValue } from '../../../utils/utils';
import { setAccount } from '../../../state/accountService';

class DefaultAddress extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      loading: false,
      error: null,
    };

    this.state = {
      ...this.initialState,
    };

    this.onCountryChange = this.onCountryChange.bind(this);
    this.onStateChange = this.onStateChange.bind(this);
    this.onPhoneChange = this.onPhoneChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onCountryChange(option) {
    this.onChange({
      target: {
        name: 'country',
        value: option.label,
      },
    });
  }

  onStateChange(option) {
    this.onChange({
      target: {
        name: 'province',
        value: option.label,
      },
    });
  }

  onPhoneChange(number) {
    if (!number) return;

    this.onChange({
      target: {
        name: 'phone',
        value: number,
      },
    });
  }

  onChange(e) {
    const { account, dispatch, site } = this.props;

    const newAccount = {
      ...account,
      defaultAddress: {
        ...account.defaultAddress,
        [e.target.name]: e.target.value,
      },
    };

    if (e.target.name === 'country') {
      newAccount.defaultAddress.province = null;
    }

    dispatch(setAccount(newAccount, site.siteUID));
  }

  async onSubmit(e) {
    e.preventDefault();

    const { getAccount, updateAddress } = this.props;

    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    const response = await updateAddress(this.props.account.defaultAddress);

    getAccount();

    this.setState((prevState) => ({
      ...prevState,
      loading: false,
    }));

    if (response.error) {
      this.setState((prevState) => ({
        ...prevState,
        error: response.error.message,
      }));
    } else if (response.customerUserErrors.length !== 0) {
      this.setState((prevState) => ({
        ...prevState,
        error: response.customerUserErrors[0].message,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        error: null,
      }));
    }
  }

  render() {
    const { account, site } = this.props;

    const selectCountries = Object.keys(countries).map((countryCode) => ({
      label: countries[countryCode].name,
      value: countries[countryCode].name,
    }));

    const selectedCountry = Object.keys(countries).find(
      (countryCode) =>
        countries[countryCode].name === account?.defaultAddress?.country
    );

    const selectStates = Object.keys(
      countries[selectedCountry]?.states || []
    ).map((stateCode) => ({
      label: countries[selectedCountry]?.states[stateCode],
      value: countries[selectedCountry]?.states[stateCode],
    }));

    const selectedState = Object.keys(
      countries[selectedCountry]?.states || []
    ).find(
      (stateCode) =>
        countries[selectedCountry].states[stateCode] ===
        account.defaultAddress?.province
    );

    return (
      <div className="account-section account-section__address">
        <div className="account-section__title">
          <h3>
            {getCmsValue(site.strings.accountPage__profile__addressTitle, site)}
          </h3>
        </div>
        <form className="account-section__form" onSubmit={this.onSubmit}>
          <div className="form__input-wrapper">
            <label htmlFor="country">
              {getCmsValue(
                site.strings.accountPage__profile__editAddressCountry,
                site
              )}
              <Select
                name="country"
                options={selectCountries}
                onChange={this.onCountryChange}
                value={{
                  label: countries[selectedCountry]?.name,
                  value: selectedCountry,
                }}
              />
            </label>
            <label htmlFor="firstName">
              {getCmsValue(
                site.strings.accountPage__profile__editAddressFirstName,
                site
              )}
              <input
                name="firstName"
                type="text"
                value={account.defaultAddress?.firstName || ''}
                onChange={this.onChange}
                required
              />
            </label>
            <label htmlFor="lastName">
              {getCmsValue(
                site.strings.accountPage__profile__editAddressLastName,
                site
              )}
              <input
                name="lastName"
                type="text"
                value={account.defaultAddress?.lastName || ''}
                onChange={this.onChange}
                required
              />
            </label>
            <label htmlFor="address1">
              {getCmsValue(
                site.strings.accountPage__profile__editAddressAddressOne,
                site
              )}
              <input
                name="address1"
                type="text"
                value={account.defaultAddress?.address1 || ''}
                onChange={this.onChange}
                required
              />
            </label>
            <label htmlFor="address2">
              {getCmsValue(
                site.strings.accountPage__profile__editAddressAddressTwo,
                site
              )}
              <input
                name="address2"
                type="text"
                value={account.defaultAddress?.address2 || ''}
                onChange={this.onChange}
              />
            </label>
            <label htmlFor="city">
              {getCmsValue(
                site.strings.accountPage__profile__editAddressCity,
                site
              )}
              <input
                name="city"
                type="text"
                value={account.defaultAddress?.city || ''}
                onChange={this.onChange}
                required
              />
            </label>
            {selectStates.length > 0 && (
              <label htmlFor="province">
                {getCmsValue(
                  site.strings.accountPage__profile__editAddressProvince,
                  site
                )}
                <Select
                  name="province"
                  options={selectStates}
                  onChange={this.onStateChange}
                  value={{
                    label: account.defaultAddress?.province || '',
                    value: selectedState,
                  }}
                />
              </label>
            )}
            <label htmlFor="zip">
              {getCmsValue(
                site.strings.accountPage__profile__editAddressZip,
                site
              )}
              <input
                name="zip"
                type="text"
                value={account.defaultAddress?.zip || ''}
                onChange={this.onChange}
                required
              />
            </label>
            <label htmlFor="phone">
              {getCmsValue(
                site.strings.accountPage__profile__editAddressPhone,
                site
              )}
              <PhoneInput
                onChange={this.onPhoneChange}
                value={account.defaultAddress?.phone || ''}
                defaultCountry="US"
              />
            </label>
            <span className="error">{this.state.error}</span>
          </div>
          <div className="form__submit-wrapper">
            <button type="submit" disabled={this.state.loading}>
              {getCmsValue(
                site.strings.accountPage__profile__addressSubmit,
                site
              )}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default withAccount(DefaultAddress);
